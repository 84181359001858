<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          Teave
        </h1>
        <h2 class="subtitle">
          <strong>Rakenduse versioon: </strong> v{{ $config.version }}<br>
          <strong>Api versioon: </strong> <a :href="$config.api.doc" target="_blank">v{{ settings && settings.version }}</a>
        </h2>
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Disklaimer:</strong>
                <br>
                Puudub igasugune ambitsioon selle tööriista
                arendamiseks suunal Project-GC. Nimetuse sarnasus on lihtsalt halb nali.<br>
                Kasutaja andmeid hoitakse süsteemis ainult sessiooni vältel.<br>
                Süsteemi päringud võivad näidata aiateibaid.<br>
                Vahest võtavad päringud kaua aega, see on normaalne, toimub andmete uuendamine, oota ära.<br>
                Asi võib katki minna. Kui nii juhtub, siis võib sellest GP foorumi vastavas teemas teada anda.
              </p>
              <p v-if="settings">
                <strong>Aarete võrdlemine</strong>
                <br>
                Aarete seisu uuendatakse alates iga {{ settings.config.caches.interval }} sekundi tagant<br>
                Aardeid võrreldakse väljade - "{{ settings.config.caches.compare.join('", "') }}" - alusel<br>
                Tulevikus võib lisanduda peitmise kuupäeva ja koordinaatide alusel võrdlemine
              </p>
              <p v-if="settings">
                <strong>Leiulogide võrdlemine</strong>
                <br>
                Kasutaja leiulogide seisu uuendatakse alates iga {{ settings.config.logs.interval }} sekundi tagant<br>
                Leide võrreldakse väljade - "{{ settings.config.logs.compare.join('", "') }}" - alusel<br>
                Palju õnne kõikidele neile, kes on manipuleerinud väljakutsete jaoks GC leidude kuupäevadega.
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>

import apiService from '@/services/ApiService'
import { error } from '@/utils'

export default {
  name: 'About',
  data() {
    return {
      settings: null
    }
  },
  created() {
    apiService.loadConfig()
      .then(response => {
        if (response.ok) {
          this.settings = response.data
        }
      })
      .catch(error)
  }
}
</script>
